import * as types from "./types";

const clearHomeDeliveryUnconfirmedNotification = () => ({
  type: types.CLEAR_HOME_DELIVERY_UNCONFIRMED_NOTIFICATION,
  payload: 0,
});

const clearPickupUnconfirmedNotification = () => ({
  type: types.CLEAR_PICKUP_UNCONFIRMED_NOTIFICATION,
  payload: 0,
});

const clearCustomerArrivedNotification = () => ({
  type: types.CLEAR_CUSTOMER_ARRIVED_NOTIFICATION,
  payload: 0,
});

export default {
  clearHomeDeliveryUnconfirmedNotification,
  clearPickupUnconfirmedNotification,
  clearCustomerArrivedNotification
};
