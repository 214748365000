import * as types from "./types";

const fetchPayments = () => ({
  type: types.FETCH_PAYMENTS,
  payload: {
    request: {
      method: "get",
      url: "/api/v1/payment-types/list",
    },
  },
});

const initiateMagnatiTransaction = (payload: any) => ({
  type: types.INITIATE_MAGNATI_TRANSACTION,
  payload: {
    request: {
      method: "post",
      url: "/api/v1/payment-partners/magnati/transactions",
      data: payload,
    },
  },
});

const cancelMagnatiTransaction = (transRefId: any, data: any) => ({
  type: types.CANCEL_MAGNATI_TRANSACTION,
  payload: {
    request: {
      method: "put",
      url: "/api/v1/payment-partners/magnati/transactions/"+transRefId,
      data: data,
    },
  },
});

const processStripeConnectTerminalPayment = (stripeLocationId: any) => ({
  type: types.PROCESS_STRIPE_CONNECT_TERMINAL_PAYMENT,
  payload: {
    request: {
      method: "get",
      url: "/api/v1/payment-partners/stripe_connect/connection_tokens/new?stripe_location_id="+stripeLocationId,
    },
  },
});

const initiateStripeConnectPaymentIntentCreate = (payload: any) => ({
  type: types.PROCESS_STRIPE_CONNECT_PAYMENT_INTENT,
  payload: {
    request: {
      method: "post",
      url: "/api/v1/payment-partners/stripe_connect/payment_intents",
      data: payload,
    },
  },
});

const fetchStripeConnectTerminalPayment = (paymentIntentId: any) => ({
  type: types.FETCH_STRIPE_CONNECT_TERMINAL_PAYMENT,
  payload: {
    request: {
      method: "get",
      url: `/api/v1/payment-partners/stripe_connect/payment_intents/${paymentIntentId}`
    },
  },
});

const cancelStripeConnectTerminalPayment = (paymentIntentId: any, payload: any) => ({
  type: types.CANCEL_STRIPE_CONNECT_TERMINAL_PAYMENT,
  payload: {
    request: {
      method: "delete",
      url: `/api/v1/payment-partners/stripe_connect/payment_intents/${paymentIntentId}`,
      data: payload
    },
  },
});

const initiateRazorpayTransaction = (payload: any) => ({
  type: types.INITIATE_RAZORPAY_TRANSACTION,
  payload: {
    request: {
      method: "post",
      url: "/api/v1/payment-partners/razorpay/transactions",
      data: payload,
    },
  },
});

const cancelRazorpayTransaction = (transRefId: any, data: any) => ({
  type: types.CANCEL_RAZORPAY_TRANSACTION,
  payload: {
    request: {
      method: "put",
      url: "/api/v1/payment-partners/razorpay/transactions/"+transRefId,
      data: data,
    },
  },
});

export default {
  fetchPayments,
  initiateMagnatiTransaction,
  cancelMagnatiTransaction,
  processStripeConnectTerminalPayment,
  initiateStripeConnectPaymentIntentCreate,
  fetchStripeConnectTerminalPayment,
  cancelStripeConnectTerminalPayment,
  initiateRazorpayTransaction,
  cancelRazorpayTransaction,
};