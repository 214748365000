import actions from "./actions";

const fetchReprints = actions.fetchReprints;
const resetReprints = actions.resetReprints;
const markOrderReprinted = actions.markOrderReprinted;
const getLocalPrint = actions.getLocalPrint;

export default {
  fetchReprints,
  resetReprints,
  markOrderReprinted,
  getLocalPrint,
};
