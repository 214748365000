import { useEffect, useState } from "react";

enum PAYMENT_PARTNER {
  STRIPE_CONNECT = "Stripe Connect",
  MAGNATI = "Magnati",
  RAZORPAY = "Razorpay",
}

enum STRIPE_CONNECT_ERROR {
  GENERIC_ERROR = "generic_error",
  DISCONNECTION_ERROR = "disconnection_error",
  DISCOVERY_ERROR = "discovery_error",
  NO_READERS = "no_readers_error",
  READER_NOT_FOUND = "reader_not_found_error",
  REQUIRES_CONFIRMATION = "requires_confirmation",
  REQUEST_TIMED_OUT = "request_timed_out",
  READER_CONNECT_ERROR = "unable_to_connect_reader_error",
  PAYMENT_CONFIRMATION_ERROR = "payment_confirmation_error",
  PAYMENT_COLLECTION_ERROR = "payment_collection_error",
}

enum STRIPE_CONNECT_TXN_STEP {
  INITIATING_TERMINAL = "initiating_terminal",
  DISCOVERING_READER = "discovering_reader",
  COLLECT_PAYMENT_INTENT = "collecting_payment_intent",
  CANCEL_PAYMENT_INTENT = "cancelling_payment_intent",
  CONFIRM_PAYMENT_INTENT = "confirm_payment_intent",
}

enum MAGNATI_ERROR{
  GENERIC_ERROR = "generic_error",
  NO_READERS = "no_readers_error",
  REQUEST_TIMED_OUT = "request_timed_out",
}

const getIconImageSource = (path: string) => {
  let a = "";
  try {
    a = require(`../../images/${path}`);
  } catch (err) {
    // fallback image if it fails to load
    a = require(`../../images/payment-types/cash-black.png`);
  }
  return a;
};

/**
 * if a location has a payment partner enabled, show that partner's
 * payment type in list only if that partner's terminal device(s)
 * is connected and available.
 */
const filterTakeawayPaymentPartnersByLocation = (
  paymentTypesList,
  locationPaymentPartner,
  integratedPaymentPartners,
  isTakeAway
) => {
  return paymentTypesList.filter((p: any) => {
    if (
      integratedPaymentPartners.includes(p.name) &&
      locationPaymentPartner &&
      locationPaymentPartner.name === p.name
    ) {
      return Boolean(
        "devices" in locationPaymentPartner &&
          typeof locationPaymentPartner == "object" &&
          isTakeAway
      );
    }
    return true;
  });
};

const filterCashRoundingPaymentTypes = (paymentTypesList: any) => {
  return paymentTypesList.filter((d: any) => {
    return d.display_name !== "Cash Rounding";
  });
};

const getPaginatedData = ({ itemsList, currentPageNo, itemsPerPage }) => {
  const endIndex = currentPageNo * itemsPerPage;
  const startIndex = endIndex - itemsPerPage;
  return itemsList.slice(startIndex, endIndex);
};

const usePagination = ({ itemsList = [], itemsPerPage = 8 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedItems, setPaginatedItems] = useState([]);

  useEffect(() => {
    if (itemsList.length > 0) {
      let totPages = Math.ceil(itemsList.length / itemsPerPage);
      if (totPages > 0 && totalPages === 0) {
        setTotalPages(totPages);
        setPaginatedItems(
          getPaginatedData({ itemsList, currentPageNo: 1, itemsPerPage })
        );
      }
    }
  }, [itemsList.length]);

  useEffect(() => {
    if (itemsList.length > 0 && currentPage > 0) {
      setPaginatedItems(
        getPaginatedData({
          itemsList,
          currentPageNo: currentPage,
          itemsPerPage,
        })
      );
    }
  }, [itemsList.length, currentPage]);

  const navigateNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((c) => c + 1);
    }
  };

  const navigatePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((c) => c - 1);
    }
  };

  return {
    paginatedItems,
    totalPages,
    currentPage,
    navigateNextPage,
    navigatePrevPage,
  };
};

export {
  PAYMENT_PARTNER,
  STRIPE_CONNECT_ERROR,
  STRIPE_CONNECT_TXN_STEP,
  MAGNATI_ERROR,
  getIconImageSource,
  filterTakeawayPaymentPartnersByLocation,
  filterCashRoundingPaymentTypes,
  getPaginatedData,
  usePagination,
};
