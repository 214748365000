const storeInitialState = {
  company: {
    selected_branch: {},
    company_details: {},
  },
  currentTab: "",
  order: {},
  theme: {
    dark_mode: false,
  },
  drivers: {
    company_drivers: [],
    logistics_partner_drivers: [],
  },
  loader: {
    loading: false,
  },
  branches: [],
  payments: [],
  reprints: [],
  cancelReasons: [],
  notifications: {
    home_delivery: {
      count: 0,
    },
    pickup: {
      count: 0,
    },
    customer_arrived: {
      count: 0,
    },
  },
  limboStateOrders: [],
  logisticPartners: [],
  printSettings: [],
  riderProximity: {},
};

export default storeInitialState;
