import { combineReducers } from "redux";
import * as types from "./types";
import {
  getOrdersStats,
  removeOrderFromList,
} from "../../../helpers/orderFunctions";
import * as orderEventTypes from "../orderEvents/types";
import * as orderTypes from "../order/types";

const initialOrdersState = {
  refresh_required: false,
  is_loading: true,
  count: 0,
  orders: [],
} as any;

const unconfirmedPickupOrdersReducer = (
  state: any = initialOrdersState,
  action: any
) => {
  let index, newState, orderList, updatedOrderList;
  switch (action.type) {
    case types.FETCH_UNCONFIRMED_PICKUP_ORDERS_SUCCESS:
      let updatedState = getOrdersStats(action.payload.data || []);
      return { ...updatedState, is_loading: false, refresh_required: false };
    case types.SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_SUCCESS:
      // Cta button click - filter and remove order on dispatch success
      updatedOrderList = removeOrderFromList(
        action.meta.previousAction.param.order_id,
        state.orders
      );
      return { ...updatedOrderList };
    case types.SET_UNCONFIRMED_PICKUP_ORDER_REJECT_FAIL:
      if (action.error !== undefined && action.error.status === 404) {
        updatedOrderList = removeOrderFromList(
          action.meta.previousAction.param.order_id,
          state.orders
        );
        return { ...updatedOrderList };
      }
      return state;
    case types.SET_UNCONFIRMED_PICKUP_ORDER_REJECT_SUCCESS:
      // Cta button click - filter and remove order on dispatch success
      updatedOrderList = removeOrderFromList(
        action.meta.previousAction.param.order_id,
        state.orders
      );
      return { ...updatedOrderList };

    case orderTypes.FETCH_ORDER_SUCCESS:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.data.order_id;
      });
      if (index === -1) {
        return state;
      }
      orderList = state.orders;
      orderList[index] = action.payload.data;
      newState = getOrdersStats(orderList);
      return { ...newState, refresh_required: false };
    case orderTypes.REMOVE_EXPIRED_ORDER:
      // Remove the order from list once it completes 24 hours.
      updatedOrderList = removeOrderFromList(action.payload, state.orders);
      return { ...updatedOrderList };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED:
      if (
        (action.payload.order_type === "online" &&
          action.payload.order_status === "unconfirmed" &&
          action.payload.takeaway) ||
        (action.payload.order_type === "walk_in" &&
          action.payload.order_status === "unconfirmed")
      ) {
        // Push order into the unconfirmed orders list if it doesn't exist
        index = state.orders.findIndex((order: any) => {
          return order.order_id === action.payload.order_id;
        });
        if (index !== -1) {
          // order is already in the list
          return state; // do nothing
        }
        if (
          action.payload.order_payload == undefined ||
          action.payload.order_payload == {}
        ) {
          // Order payload is not present
          return { ...state, count: state.count + 1, refresh_required: true };
        }
        // Order payload is present
        let updatedOrderList = [...state.orders, action.payload.order_payload];
        let updatedState = getOrdersStats(updatedOrderList);
        return { ...updatedState, refresh_required: false };
      }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CONFIRMED:
      // Pusher event - filter and remove confirmed order
      let updateCurrentStateObject = removeOrderFromList(
        action.payload.order_id,
        state.orders
      );
      return { ...updateCurrentStateObject };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_REJECTED:
      // Pusher event - filter and remove order from current state if exists
      newState = getOrdersStats(
        state.orders.filter((order: any) => {
          return action.payload.order_id !== order.order_id;
        })
      );
      return { ...newState, refresh_required: false };
    default:
      return state;
  }
};

const newPickupOrdersReducer = (
  state: any = initialOrdersState,
  action: any
) => {
  let index, updatedOrderList;
  switch (action.type) {
    case types.FETCH_NEW_PICKUP_ORDERS_SUCCESS:
      let newState = getOrdersStats(action.payload.data || []);
      return { ...newState, is_loading: false, refresh_required: false };
    case types.SET_PICKUP_ORDER_MARK_AS_READY_SUCCESS:
      // Cta button click - filter and remove order on dispatch success
      let updatedOrderList = removeOrderFromList(
        action.meta.previousAction.param.order_id,
        state.orders
      );
      return { ...updatedOrderList };
    case orderTypes.FETCH_ORDER_SUCCESS:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.data.order_id;
      });
      if (index === -1) {
        return state;
      }
      let orderList = state.orders;
      orderList[index] = action.payload.data;
      newState = getOrdersStats(orderList);
      return { ...newState, refresh_required: false };
    case orderTypes.REMOVE_EXPIRED_ORDER:
      // Remove the order from list once it completes 24 hours.
      updatedOrderList = removeOrderFromList(action.payload, state.orders);
      return { ...updatedOrderList };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CONFIRMED:
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED:
      if (
        !(["walk_in","online"].includes(action.payload.order_type)) ||
        action.payload.order_status !== "new" || 
        action.payload.scheduled ||
        !action.payload.takeaway
      ) {
        return state; // do nothing
      }
      // TODO: handle pickup order created (pickup also has new state)
      // Push order into the new orders list if it doesn't exist
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });

      if (index !== -1) {
        // order is already in the list
        return state; // do nothing
      }

      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, count: state.count + 1, refresh_required: true };
      } else {
        // Order payload is present
        let updatedOrderList = [...state.orders, action.payload.order_payload];
        let updatedState = getOrdersStats(updatedOrderList);
        return { ...updatedState, refresh_required: false };
      }
    case orderEventTypes.PUSHER_RECEIVED_PICKUP_ORDER_MARKED_AS_READY:
      // Pusher event - filter and remove ready order
      let updateCurrentStateObject = removeOrderFromList(
        action.payload.order_id,
        state.orders
      );
      return { ...updateCurrentStateObject };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_VOIDED:
      // Pusher event - filter and remove order from current state if exists
      let filterOrders = getOrdersStats(
        state.orders.filter((order: any) => {
          return action.payload.order_id !== order.order_id;
        })
      );
      return { ...filterOrders };
    case orderEventTypes.PUSHER_RECEIVED_CUSTOMER_ARRIVED:
      if (
        action.payload.order_status !== "new"
      ) {
        return state; // do nothing
      }
      // TODO: handle pickup order created (pickup also has new state)
      // Push order into the new orders list if it doesn't exist
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });

      if (index !== -1) {
        // order is already in the list
        return state; // do nothing
      }

      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, count: state.count + 1, refresh_required: true };
      } else {
        // Order payload is present
        let updatedOrderList = [...state.orders, action.payload.order_payload];
        let updatedState = getOrdersStats(updatedOrderList);
        return { ...updatedState, refresh_required: false };
      }
    default:
      return state;
  }
};

const readyPickupOrdersReducer = (
  state: any = initialOrdersState,
  action: any
) => {
  let index, updatedOrderList;
  switch (action.type) {
    case types.FETCH_READY_PICKUP_ORDERS_SUCCESS:
      let newState = getOrdersStats(action.payload.data || []);
      return { ...newState, is_loading: false, refresh_required: false };
    case types.SET_ORDER_COLLECTED_SUCCESS:
      let updateState = removeOrderFromList(
        action.meta.previousAction.param.order_id,
        state.orders
      );
      return { ...updateState };
    case orderTypes.FETCH_ORDER_SUCCESS:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.data.order_id;
      });
      if (index === -1) {
        return state;
      }
      let orderList = state.orders;
      orderList[index] = action.payload.data;
      newState = getOrdersStats(orderList);
      return { ...newState, refresh_required: false };
    case orderTypes.REMOVE_EXPIRED_ORDER:
      // Remove the order from list once it completes 24 hours.
      updatedOrderList = removeOrderFromList(action.payload, state.orders);
      return { ...updatedOrderList };
    case orderEventTypes.PUSHER_RECEIVED_PICKUP_ORDER_MARKED_AS_READY:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });

      if (index !== -1) {
        // order is already in the list
        return state; // do nothing
      }

      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, count: state.count + 1, refresh_required: true };
      } else {
        // Order payload is present
        updatedOrderList = [...state.orders, action.payload.order_payload];
        let updatedState = getOrdersStats(updatedOrderList);
        return { ...updatedState, refresh_required: false };
      }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_COLLECTED:
      let updateCurrentStateObject = removeOrderFromList(
        action.payload.order_id,
        state.orders
      );
      return { ...updateCurrentStateObject };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CONFIRMED:
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED:
      if (
        action.payload.order_status !== "ready" ||
        action.payload.scheduled ||
        !action.payload.takeaway
      ) {
        return state; // do nothing
      }

      // TODO: handle pickup order created (pickup also has new state)
      // Push order into the new orders list if it doesn't exist
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });

      if (index !== -1) {
        // order is already in the list
        return state; // do nothing
      }

      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, count: state.count + 1, refresh_required: true };
      } else {
        // Order payload is present
        let updatedOrderList = [...state.orders, action.payload.order_payload];
        let updatedState = getOrdersStats(updatedOrderList);
        return { ...updatedState, refresh_required: false };
      }
    // TODO: handled from header
    // case orderEventTypes.PUSHER_RECEIVED_ORDER_UPDATED:
    //   if (Object.keys(action.payload.order_payload || {}).length > 0) {
    //     let index = state.orders.findIndex((order: any) => {
    //       return order.order_id === action.payload.order_id;
    //     });
    //     if(index!== -1) {
    //       let newOrdersList = state.orders
    //       newOrdersList[index] = action.payload.order_payload;
    //       let newState = getOrdersStats(newOrdersList);
    //       return { ...newState, refresh_required: false };
    //     } else {
    //       return { ...state }
    //     }
    //   } else return { ...state, count: state.count+1, refresh_required: true };
    case orderEventTypes.PUSHER_RECEIVED_CUSTOMER_ARRIVED:
      if (
        action.payload.order_status !== "ready"
      ) {
        return state; // do nothing
      }
      // TODO: handle pickup order created (pickup also has new state)
      // Push order into the new orders list if it doesn't exist
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });

      if (index !== -1) {
        // order is already in the list
        return state; // do nothing
      }

      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, count: state.count + 1, refresh_required: true };
      } else {
        // Order payload is present
        let updatedOrderList = [...state.orders, action.payload.order_payload];
        let updatedState = getOrdersStats(updatedOrderList);
        return { ...updatedState, refresh_required: false };
      }

    default:
      return state;
  }
};

const collectedPickupOrdersReducer = (
  state: any = initialOrdersState,
  action: any
) => {
  let index, orderList;
  switch (action.type) {
    case types.FETCH_COLLECTED_PICKUP_ORDERS_SUCCESS:
      let newState = getOrdersStats(action.payload.data || []);
      return { ...newState, is_loading: false, refresh_required: false };
    case types.SET_ORDER_COLLECTED_SUCCESS:
      // return { ...state, count: state.count + 1 };
      return state;
    case orderTypes.FETCH_ORDER_SUCCESS:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.data.order_id;
      });
      if (index === -1) {
        return state; // do nothing
      }
      orderList = state.orders;
      orderList[index] = action.payload.data;
      return { ...state, orders: orderList };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_REPRINTED:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });
      if (index === -1) {
        return state; // do nothing
      }
      orderList = state.orders;
      orderList[index] = action.payload.order_payload;
      return { ...state, orders: orderList };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_COLLECTED:
      index = state.orders.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });

      if (index !== -1) {
        // order is already in the list
        return state; // do nothing
      }

      if (
        action.payload.order_payload == undefined ||
        action.payload.order_payload == {}
      ) {
        // Order payload is not present
        return { ...state, count: state.count + 1, refresh_required: true };
      } else {
        // Order payload is present
        let updatedOrderList = [...state.orders, action.payload.order_payload];
        let updatedState = getOrdersStats(updatedOrderList);
        return { ...updatedState, refresh_required: false };
      }
    case types.SET_COLLECTED_ORDERS_REFRESH_REQUIRED:
      return { ...state, refresh_required: true };
    default:
      return state;
  }
};

const reducer = combineReducers({
  unconfirmed_pickup_orders: unconfirmedPickupOrdersReducer,
  new_pickup_orders: newPickupOrdersReducer,
  ready_pickup_orders: readyPickupOrdersReducer,
  collected_pickup_orders: collectedPickupOrdersReducer,
});

export default reducer;
