import { combineReducers } from "redux";
import * as types from "./types";
import * as orderEventTypes from "../orderEvents/types";

let initialState = { count: 0 };

const homeDeliveryNotificationReducer = (
  state: any = initialState,
  action: any
) => {
  switch (action.type) {
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED:
      if (
        action.payload.order_type === "online" &&
        action.payload.order_status === "unconfirmed" &&
        !action.payload.takeaway
      ) {
        return { ...state, count: state.count + 1 };
      }
      return state;
    case types.CLEAR_HOME_DELIVERY_UNCONFIRMED_NOTIFICATION:
      return { ...state, count: 0 };
    default:
      return state;
  }
};

const pickupNotificationReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED:
      if (
        (action.payload.order_type === "online" &&
          action.payload.order_status === "unconfirmed" &&
          action.payload.takeaway) ||
        (action.payload.order_type === "walk_in" &&
          action.payload.order_status === "unconfirmed")
      ) {
        return { ...state, count: state.count + 1 };
      }
      return state;
    case types.CLEAR_PICKUP_UNCONFIRMED_NOTIFICATION:
      return { ...state, count: 0 };
    default:
      return state;
  }
};

const customerArrivedNotificationReducer = (
  state: any = { count: 0, order_number: null },
  action: any
) => {
  switch (action.type) {
    case orderEventTypes.PUSHER_RECEIVED_CUSTOMER_ARRIVED:
      return {
        ...state,
        count: state.count + 1,
        order_number: action.payload.order_number,
      };
    case types.CLEAR_CUSTOMER_ARRIVED_NOTIFICATION:
      return { ...state, count: 0, order_number: null };
    default:
      return state;
  }
};

const reducer = combineReducers({
  home_delivery: homeDeliveryNotificationReducer,
  pickup: pickupNotificationReducer,
  customer_arrived: customerArrivedNotificationReducer,
});

export default reducer;
