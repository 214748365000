import actions from "./actions";

const clearHomeDeliveryUnconfirmedNotification = actions.clearHomeDeliveryUnconfirmedNotification;
const clearPickupUnconfirmedNotification = actions.clearPickupUnconfirmedNotification;
const clearCustomerArrivedNotification = actions.clearCustomerArrivedNotification;

export default {
  clearHomeDeliveryUnconfirmedNotification,
  clearPickupUnconfirmedNotification,
  clearCustomerArrivedNotification
}
