import actions from "./actions";

const fetchPayments = actions.fetchPayments;
const initiateMagnatiTransaction = actions.initiateMagnatiTransaction;
const cancelMagnatiTransaction = actions.cancelMagnatiTransaction;
const processStripeConnectTerminalPayment = actions.processStripeConnectTerminalPayment;
const initiateStripeConnectPaymentIntentCreate = actions.initiateStripeConnectPaymentIntentCreate;
const fetchStripeConnectTerminalPayment = actions.fetchStripeConnectTerminalPayment;
const cancelStripeConnectTerminalPayment = actions.cancelStripeConnectTerminalPayment;
const initiateRazorpayTransaction = actions.initiateRazorpayTransaction;
const cancelRazorpayTransaction = actions.cancelRazorpayTransaction;

export default {
  fetchPayments,
  initiateMagnatiTransaction,
  cancelMagnatiTransaction,
  processStripeConnectTerminalPayment,
  initiateStripeConnectPaymentIntentCreate,
  fetchStripeConnectTerminalPayment,
  cancelStripeConnectTerminalPayment,
  initiateRazorpayTransaction,
  cancelRazorpayTransaction,
};
