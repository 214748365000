export const FETCH_REPRINTS = "reprints/FETCH_REPRINTS";
export const FETCH_REPRINTS_SUCCESS = "reprints/FETCH_REPRINTS_SUCCESS";
export const FETCH_REPRINTS_FAIL = "reprints/FETCH_REPRINTS_FAIL";
export const RESET_REPRINTS = "reprints/RESET_REPRINTS";
export const SET_ORDER_MARK_AS_REPRINTED =
  "reprints/SET_ORDER_MARK_AS_REPRINTED";
export const SET_ORDER_MARK_AS_REPRINTED_SUCCESS =
  "reprints/SET_ORDER_MARK_AS_REPRINTED_SUCCESS";
export const SET_ORDER_MARK_AS_REPRINTED_FAIL =
  "reprints/SET_ORDER_MARK_AS_REPRINTED_FAIL";
export const GET_LOCAL_PRINT = "reprints/GET_LOCAL_PRINT";
export const GET_LOCAL_PRINT_SUCCESS = "reprints/GET_LOCAL_PRINT_SUCCESS";
export const GET_LOCAL_PRINT_FAIL = "reprints/GET_LOCAL_PRINT_FAIL";
