import React from "react";
import { ReactNode } from "react";
import { PAYMENT_PARTNER } from "./utils";
import StripeConnectTxnStatusView from "./paymentPartners/stripeConnect";
import MagnatiTxnStatusView from "./paymentPartners/magnati";
import RazorpayTxnStatusView from "./paymentPartners/razorpay";

const TransactionStatusView = ({
  selectedPaymentPartner,
  stripeConnectConfigs,
  magnatiConfigs,
  razorpayConfigs,
  children,
}: {
  selectedPaymentPartner: string | PAYMENT_PARTNER;
  stripeConnectConfigs: any;
  magnatiConfigs: any;
  razorpayConfigs: any;
  children: ReactNode;
}) => {
  switch (selectedPaymentPartner){
    case PAYMENT_PARTNER.STRIPE_CONNECT:
      return <StripeConnectTxnStatusView {...stripeConnectConfigs} />;
    case PAYMENT_PARTNER.MAGNATI:
      return <MagnatiTxnStatusView {...magnatiConfigs} />;
    case PAYMENT_PARTNER.RAZORPAY:
      return <RazorpayTxnStatusView {...razorpayConfigs} />;
    default:
      return <>{children}</>;
  }
};

export default TransactionStatusView;
