import { getEnvValueFor } from "../../../helpers/utils";
import * as types from "./types";

const fetchReprints = (orderId: any) => ({
  type: types.FETCH_REPRINTS,
  payload: {
    request: {
      method: "get",
      url: `/api/v1/orders/${orderId}/print-templates`,
    },
  },
});

const resetReprints = () => ({
  type: types.RESET_REPRINTS,
  payload: [],
});

const markOrderReprinted = (orderId: any) => ({
  type: types.SET_ORDER_MARK_AS_REPRINTED,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/${orderId}/mark-as-reprinted`,
    },
  },
  param: { order_id: orderId },
});

const getLocalPrint = (orderId: any) => ({
  type: types.GET_LOCAL_PRINT,
  payload: {
    client: "posApi",
    request: {
      method: "get",
      url: `${getEnvValueFor(
        "SAPAAD_CORE_BASE_URL"
      )}/local_prints/pays_bill_for_takeaway_order?order_id=${orderId}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    },
  },
});

export default {
  fetchReprints,
  resetReprints,
  markOrderReprinted,
  getLocalPrint,
};
