import { createStore, combineReducers, applyMiddleware } from "redux";
import * as reducers from "./features";
import storeInitialState from "./storeInitialState";
import { multiClientMiddleware } from "redux-axios-middleware";
import axiosClient from "./middlewares";
import {
  _getAccessToken,
  _getRefreshToken,
  _setToken,
  loadState,
  saveState,
} from "../helpers/localstorageService";
import { composeWithDevTools } from "redux-devtools-extension";
import { authOperations } from "./features/auth";
import { errorOperations } from "./features/error";
import * as Sentry from "@sentry/react";
import { reportExceptionToSentry } from "../helpers/sentry";

const rootReducer = combineReducers(reducers);

axiosClient.dmmsApi.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.message.includes("Network Error")) {
      errorOperations.setNetworkError();
    }
    if (err?.response) {
      if (err?.response?.status === 401) {
        authOperations.setSessionExpired();
      }
      if (err?.response?.status === 500) {
        reportExceptionToSentry({
          title: "Received response with status 500",
          message: "Please check breadcrumb log for more trace of events",
        });
      }
    }
    return Promise.reject(err);
  }
);

const options = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      ({ getState, dispatch }: any, config: any) => {
        const token = localStorage.getItem("jwt");
        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
      },
    ],
  },
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  attachReduxState: false,
});

const persistedState = loadState();
let initialState = storeInitialState as any;
initialState = { ...persistedState };
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(
    applyMiddleware(
      multiClientMiddleware(
        {
          default: { client: axiosClient.dmmsApi },
          authmsApi: { client: axiosClient.authmsApi },
          posApi: { client: axiosClient.posApi },
        },
        options
      )
    ),
    sentryReduxEnhancer
  )
);

store.subscribe(() => {
  saveState({
    limboStateOrders: store.getState().limboStateOrders,
  });
});

const dispatchAction = store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export { store, dispatchAction };
