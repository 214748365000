import * as Sentry from "@sentry/react";

const razorpayLogger = {
  addDebugLog: (message: string) => {
    Sentry.addBreadcrumb({
      category: "payments",
      message,
      level: "debug",
    });
  },
};

export { razorpayLogger };
