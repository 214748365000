import { useEffect, useRef, useState } from "react";
import { PAYMENT_STATUS } from "../../../../types/payments";
import { usePusher } from "../../../../hooks/usePusher";
import { PAYMENT_PARTNER } from "../../utils";
import type { Channel } from "pusher-js";
import { razorpayLogger } from "./utils";

interface Params {
  amount: number | string;
  paymentType: () => any;
  getDeviceId: () => any;
  clearDeviceId: any;
  locationId: number;
  razorpayTxn?: {
    initiate: (payload: any) => Promise<any> | void;
    cancel: (transRefId: any, data: any) => Promise<any> | void;
  };
  handleCloseTxnStatusView(): void;
  onTransactionSuccess(configs?: any): void;
}

const useRazorpayPayment = ({
  amount,
  paymentType,
  getDeviceId,
  clearDeviceId,
  locationId,
  razorpayTxn = {
    initiate: (payload) => {},
    cancel: (transRefId, data) => {},
  },
  handleCloseTxnStatusView,
  onTransactionSuccess,
}: Params) => {
  const [configs, setConfigs] = useState();
  const [isTxnInitiateFailed, setIsTxnInitiateFailed] = useState(false);
  const [isTxnInitiated, setIsTxnInitiated] = useState(false);
  const [txnStatus, setTxnStatus] = useState(PAYMENT_STATUS.WAITING);

  const [enableCancelTxn, setEnableCancelTxn] = useState(true);
  const [txnDeclineReason, setTxnDeclineReason] = useState("");
  const pusher = usePusher();

  const cancelTxnTimeout = useRef<any>(null);
  const txnSuccessTimeout = useRef<any>(null);

  const resetTxnProgressValues = () => {
    txnSuccessTimeout.current = null;
    cancelTxnTimeout.current = null;
  };

  useEffect(() => {
    return () => {
      // Clean up on unmount
      if (txnSuccessTimeout.current) {
        clearTimeout(txnSuccessTimeout.current);
      }
      if (cancelTxnTimeout.current) {
        clearTimeout(cancelTxnTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    let channel: Channel | null = null;
    if (paymentType.name == PAYMENT_PARTNER.RAZORPAY) {
      if (configs && !isTxnInitiated) {
        setTxnStatus(PAYMENT_STATUS.CANCELLED);
      }
      if (isTxnInitiateFailed) {
        setTxnStatus(PAYMENT_STATUS.FAILED);
        setTxnDeclineReason("Internet connection failure");
      }
      if (configs && isTxnInitiated) {
        setTxnStatus(PAYMENT_STATUS.IN_PROGRESS);
        if (!configs.referenceId) {
          console.error("reference id is undefined");
          razorpayLogger.addDebugLog("Razorpay - reference id undefined");
        } else {
          let { referenceId, deviceId, locationId } = configs;
          channel = pusher.subscribe(
            `private_razorpay_transaction_${deviceId}`
          );
          channel.bind("payment_success", (data: any) => {
            const {
              transaction_reference_id,
              transaction_id,
              metadata: metaData,
            } = data;
            if (transaction_reference_id == referenceId) {
              let cardNo = metaData.card_no.toString();
              razorpayLogger.addDebugLog("Razorpay - Transaction successful");
              clearTimeout(txnSuccessTimeout.current);
              clearTimeout(cancelTxnTimeout.current);
              setTxnStatus(PAYMENT_STATUS.PAID);
              handleRazorpayTransactionSuccess({
                refCode: cardNo.slice(-4),
                metaData,
              });
              setTimeout(() => {
                resetTxnProgressValues();
                handleCloseTxnStatusView();
                clearDeviceId();
              }, 5000);
              channel?.unbind();
            }
          });
          channel.bind("payment_failed", (data: any) => {
            razorpayLogger.addDebugLog("Razorpay - Transaction failed");
            if (data.transaction_reference_id == referenceId) {
              clearTimeout(txnSuccessTimeout.current);
              clearTimeout(cancelTxnTimeout.current);
              setTxnStatus(PAYMENT_STATUS.FAILED);
              if (data?.metadata?.response_from_partner) {
                setTxnDeclineReason(data?.metadata?.response_from_partner);
                handleRazorpayTransactionFail({
                  referenceId,
                  configs: { device_id: deviceId, location_id: locationId },
                });
              } else {
                setTxnDeclineReason("Internet connection failure");
              }
              resetTxnProgressValues();
              channel?.unbind();
            }
          });
          razorpayLogger.addDebugLog("Razorpay - Transaction started");
          startTransaction();
        }
      }
    }
    return () => {
      // Clean up on unmount
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, [configs, isTxnInitiateFailed]);

  const handlePaymentWithRazorpay = () => {
    setTxnStatus(PAYMENT_STATUS.WAITING);
    let referenceId = `razorpay-transaction-${locationId}-${getDeviceId()}-${new Date().getTime()}`;
    let payload = {
      device_id: getDeviceId(),
      amount: parseFloat(amount),
      payment_type_id: paymentType.id,
      location_id: locationId,
      reference_id: referenceId,
      order_type: "call-center",
    };
    setIsTxnInitiated(true);
    razorpayTxn
      .initiate(payload)
      .then((response) => {
        if (!response.error && response?.payload?.status === 200) {
          setConfigs({ referenceId, deviceId: getDeviceId(), locationId });
        }
      })
      .catch((err) => {
        setIsTxnInitiateFailed(true);
        setIsTxnInitiated(false);
        handleRazorpayTransactionFail({
          referenceId,
          configs: { device_id: getDeviceId(), location_id: locationId },
        });
      });
  };

  const startTransaction = () => {
    // DMMS will wait till 3 minutes for the the payment.
    //  If we didnt get the response within 3 minutes it will cancel the transaction.
    txnSuccessTimeout.current = setTimeout(() => {
      razorpayLogger.addDebugLog("Razorpay - Transaction timed out");
      handleTransactionTimedOut();
    }, 180000);
  };

  const handleTransactionTimedOut = () => {
    setTxnStatus(PAYMENT_STATUS.CANCELLED);
    handleRazorpayTransactionFail({
      referenceId: configs?.referenceId,
      configs: {
        device_id: configs.deviceId,
        location_id: configs.locationId,
      },
    });
    clearTimeout(txnSuccessTimeout.current);
    clearTimeout(cancelTxnTimeout.current);
  };

  const handleCancelTxnCtaClick = () => {
    handleRazorpayTransactionFail({
      referenceId: configs?.referenceId,
      configs: {
        device_id: configs.deviceId,
        location_id: configs.locationId,
      },
    });
    resetTxnProgressValues();
    handleCloseTxnStatusView();
    clearDeviceId();
  };

  const handleRazorpayTransactionSuccess = (configs: any) => {
    setIsTxnInitiateFailed(false);
    setIsTxnInitiated(false);
    setConfigs(null);
    onTransactionSuccess(configs);
  };

  const handleRazorpayTransactionFail = ({ referenceId, configs }: any) => {
    setConfigs(null);
    razorpayTxn.cancel(referenceId, configs).catch((err) => {
      console.log(err);
    });
  };

  return {
    txnDeclineReason,
    txnStatus,
    enableCancelTxn,
    handleCancelTxnCtaClick,
    handlePaymentWithRazorpay,
  };
};

export { useRazorpayPayment };
