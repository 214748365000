import axios from "axios";
import { getEnvValueFor } from "../../helpers/utils";

const dmmsApi = axios.create({
  baseURL: "",
  responseType: "json",
});

const authmsApi = axios.create({
  responseType: "json",
});

const posApi = axios.create({});

authmsApi.interceptors.response.use((response: any) => {
  console.debug(
    `[AUTHMS] ${response.config.method.toUpperCase()} ${response.status} ${
      response.config.url
    }`,
    response.data
  );
  return response;
});

export { dmmsApi, authmsApi, posApi };
