import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import CurbsideImage from "../../images/curbside-pickup.png";
import { notificationOperations } from "../../state/features/notifications";

interface iCurbsideNotificationProps {
  notificaions?: any;
  clearCustomerArrivedNotification?: any;
}

function CurbsideNotification(props: iCurbsideNotificationProps) {
  const clearCurbsideNotification = () => {
    props.clearCustomerArrivedNotification();
  };
  return (
    <div className="curbside-notification-wrapper">
      {props.notificaions.customer_arrived.count > 0 && (
        <div
          onClick={clearCurbsideNotification}
          className={`curbside-notification-item  ${
            props.notificaions.customer_arrived.count > 0 && "show"
          }`}
        >
          <div>
            <div
              className="curbside-image"
              style={{ backgroundImage: `url(${CurbsideImage})` }}
            />
            <div className="detailsContainer">
              <p>
                <FormattedMessage id="filter.curbside_pickup" />
              </p>
              <h1>
                <FormattedMessage id="curbside.notification1" /> #
                {props.notificaions.customer_arrived.order_number}
              </h1>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let notificaions = state.notifications;

  return { notificaions };
};

const mapDispatchToProps = {
  clearCustomerArrivedNotification:
    notificationOperations.clearCustomerArrivedNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurbsideNotification);
